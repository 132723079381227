//
// Color system
//
$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #2cabe3;
$indigo: #6610f2;
$purple: #613d7c;
$pink: #ff5b77;
$red: #d9534f;
$orange: #f0ad4e;
$yellow: #ffd500;
$green: #43d68f;
$teal: #5bc0de;
$cyan: #17a2b8;
$chambray: #384b96;
$turquoise: #4ab8a7;
$medmij-gray: #878787;
$jaffa: #f49835;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  chambray: $chambray,
  gray: $gray-600,
  gray-dark: $gray-800,
  turquoise: $turquoise,
  medmij-gray: $medmij-gray,
  jaffa: $jaffa
);

$theme-colors: (
  primary: $turquoise,
  secondary: $chambray,
  success: $green,
  info: $medmij-gray,
  warning: $jaffa,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);
