.ReactTable {
  .rt-resizable-header-content {
    input[type='checkbox'] {
      left: -13px;
    }
  }

  input[type='checkbox'].form-check-input {
    position: relative !important;
  }

  .rt-thead {
    &.-header {
      box-shadow: none;
      text-align: left;
    }

    &.-filters {
      select,
      input {
        @extend .form-control;
      }

      select {
        line-height: 1.5;
      }
    }

    .rt-th {
      &.-cursor-pointer {
        &::after {
          content: 'sort';
          font-family: 'Material Icons';
          position: absolute;
          right: 1rem;
          top: 0;
          line-height: 2.5rem;
        }
        &.-sort-asc {
          &::after {
            content: 'keyboard_arrow_up';
          }
        }
        &.-sort-desc {
          &::after {
            content: 'keyboard_arrow_down';
          }
        }
      }
    }

    .rt-resizable-header-content {
      line-height: 2.5rem;
      align-self: center;
      @extend .text-strong !optional;
    }
  }

  .rt-tbody {
    .rt-td {
      margin-top: 3px;
    }
  }

  .rt-noData {
    width: 100%;
    height: 250px;
  }

  .data-table-checkbox {
    margin-left: 4px;
    margin-top: 1px;
    box-shadow: none;

    &--header {
      margin-left: 9px;
      margin-top: 8px;
    }

    &::before {
      left: 13px !important;
      top: 4px !important;
    }

    &:focus,
    &:hover,
    &:active {
      box-shadow: none;
    }
  }
}
