.AppFrame {
  padding-top: 3.75rem;
  margin-bottom: 4.875rem;

  .breadcrumb {
    background: transparent;
    margin-bottom: 0;

    .active {
      span {
        padding: 0.5rem 1rem;
        display: inline-block;
      }
    }

    .breadcrumb-item + .breadcrumb-item {
      &::before {
        content: 'keyboard_arrow_right';
        font-family: 'Material Icons';
        line-height: $line-height-base;
        vertical-align: middle;
      }

      .nav-link {
        display: inline-block;
      }
    }
  }
}
