.rdt {
  //@extend .dropdown-menu;
  position: relative;
  &.rdtOpen {
    .rdtPicker {
      display: block;
    }

    td.rdtDay.rdtDisabled:hover {
      cursor: not-allowed;
      background: none;
    }

    button,
    td,
    td.rdtDay:hover,
    td.rdtHour:hover,
    td.rdtMinute:hover,
    td.rdtSecond:hover,
    .rdtTimeToggle:hover {
      cursor: pointer;
    }
    .rdtSwitch,
    .rdtPrev,
    .rdtNext {
      @extend .rounded;
      &:hover {
        background: $gray-200;
      }
    }

    .rdtCounters {
      display: inline-block;

      .rdtCounter {
        width: 2.5rem;
      }
      > div {
        float: left;
      }
    }
  }
  .rdtPicker {
    width: 271px;
    padding: 0;
    margin-top: 5px;
    @extend .dropdown-menu;
    table {
      @extend .table, .mb-0;
      border-spacing: 5px;
      border-collapse: separate;
      thead > tr > th,
      tbody > tr > td,
      tfoot > tr > td {
        text-align: center;
        font-size: 0.85rem;
        padding: 0.25rem;
      }

      thead {
        > tr {
          > th {
            border: none;
            font-weight: 400;
            &.dow {
              text-transform: uppercase;
            }
          }
        }
        &::after {
          content: '';
          position: absolute;
          border-bottom: 1px solid $gray-200;
          width: 100%;
          left: 0;
        }
      }
      tbody > tr > td {
        border: 0;
        padding: 0;
        vertical-align: middle;
        font-weight: 300;
        color: $gray-600;
        &.rdtOld,
        &.rdtNew {
          color: lighten($gray-600, 35%);
        }
      }
    }
    .rdtMonths,
    .rdtYears,
    .rdtDays {
      table > tbody > tr > td {
        &.rdtActive,
        &.rdtActive:hover {
          @extend .rounded-circle;
          @include box-shadow(
            1px 1px 3px rgba(map-get($theme-colors, success), 0.24),
            0 1px 2px rgba(map-get($theme-colors, success), 0.48)
          );
          background: map-get($theme-colors, success);
          color: white;
          font-weight: 500;
        }
        &:hover {
          @extend .rounded-circle;
          background: $gray-200;
        }
      }
    }

    .rdtDays {
      table > tbody > tr > td {
        width: 33px;
        height: 33px;
      }
    }

    .rdtToday {
      font-weight: bold;
      color: map-get($theme-colors, success);
    }

    .rdtMonths,
    .rdtYears {
      table > tbody > tr > td {
        width: 61px;
        height: 61px;
        &:hover {
          background: $gray-200;
        }
      }
    }
  }
}
