.flash-messages {
  position: fixed;
  height: 3.125rem;
  left: 50%;
  top: 1rem;
  z-index: 9000;
  transform: translateX(-50%);
  @include media-breakpoint-down(sm) {
    left: 1rem;
    max-width: 100%;
  }

  .close {
    position: relative;
    left: 30px;
    top: -13px;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  .alert {
    @each $color, $value in $theme-colors {
      &.alert-#{$color} {
        background: $value;
        border-color: $value;
        color: color-contrast($value);
      }
    }
    border-radius: 1.875rem;
    color: white !important;
  }
}
