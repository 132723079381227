.columnSmall {
  width: 12%;
}

.schakelbord {
  tbody {
    tr {
      &:hover {
        background-color: hsl(0, 0, 88%) !important;
      }

      &.node-row {
        background-color: hsl(0, 0, 94%);
      }

      .status-column {
        vertical-align: middle;
      }

      .badge-container {
        display: flex;
        align-items: center;

        .badge {
          width: 80px;
          height: 25px;
          text-align: center;
          line-height: normal;
        }
      }
    }
  }
}
