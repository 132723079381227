.Login {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-blend-mode: overlay;
}

.logo {
  height: 3rem;
}

#duo_iframe {
  width: 100%;
  min-height: 500px;
  border: none;
}
