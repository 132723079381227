@font-face {
  font-family: 'Fira Sans';
  src: url('./fonts/firasans-regular-webfont.woff2') format('woff2'),
    url('./fonts/firasans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.page-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  opacity: 0.5;
}

.page-overlay-content {
  position: relative;
}

/*
    Disable ligatures for the body (for hardcoded
    texts in the application) as well as input fields
    (which need to be set specifically).
  
    Without doing this certain combinations
    such as 'fi' or 'fl' become invisible.
    */
body,
input,
button,
select {
  font-variant-ligatures: none;
}

@import './variables';
@import '~@42.nl/ui/dist/main.scss';

// Components
@import './button';
@import '../ui/EnvBanner/EnvBanner';
@import '../ui/tables/tables';
@import '../ui/AppFrame/TopBar/Topbar';
@import '../ui/AppFrame/AppFrame';
@import '../ui/form/DateTimeInput/DateTimePicker';
@import '../ui/InfoPopup/InfoPopup';
@import '../ui/form/FormError/FormError';
@import '../ui/FlashMessages/FlashMessages';

// App Components
@import '../app/About/about';
@import '../app/Login/Login';
@import '../app/Schakelbord/schakelbord';
