$popover-color: lighten(#00588d, 70%);

/* Popover */
.Popover {
  z-index: 1100;

  svg {
    z-index: 1100;
  }
}

.Popover-body {
  max-width: 30rem;
  padding: 1.5rem 2rem;
  background-color: $popover-color;
  color: #0a2231;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
  z-index: 1100;

  @media screen and (min-width: 768px) {
    max-width: 40rem;
    padding: 2rem 3rem;
  }
}

.Popover-tip {
  fill: $popover-color;
}

label + .infopopup-target {
  margin-left: 1rem;
}
